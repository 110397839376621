<template>
    <div class="findwellness-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">Search for a practitioner</h1>
                        <h4>Search by name or city and filter by distance. Press enter when ready to find wellness</h4>
                            
                        <!-- Search Text -->
                        <div class="row d-flex flex-row justify-content-sm-center align-content-center">
                            <div class="col-md-3 col-sm-12 flex-column align-self-center p-2">
                                <select class="form-control" v-model="searchModel.type" @change="bindPractitionerTypeEvent" :disabled="dataModel.isLoading">
                                    <option v-for="type in dataModel.practitionerTypes" :key="type.id" :value="type.id">{{ type.description }}</option>
                                </select>
                            </div>
                            <div class="col-md-9 col-sm-12 flex-column align-self-center flex-grow-1 p-2">
                                <fg-input 
                                    placeholder="Search for practitioner here..." 
                                    addon-right-icon="now-ui-icons ui-1_zoom-bold" 
                                    v-model="searchModel.text" 
                                    :disabled="dataModel.isLoading"
                                    @keyup.enter.native="searchData">
                                </fg-input>
                            </div>
                        </div>

                        <!-- Distance -->
                        <div class="d-flex flex-row justify-content-sm-center align-content-center" v-if="searchModel.location.enabled">
                            <!-- Slider -->
                            <div class="col-10 col-xs-12 d-flex flex-row justify-content-sm-center align-content-center">
                                <div class="flex-column align-self-center p-2">{{ sliderConfig.range.min }}km</div>
                                <div class="flex-column align-self-center flex-grow-1 p-2">
                                    <slider class="slider-success"
                                        ref="distance-slider" 
                                        v-model="searchModel.location.distance" 
                                        :range="sliderConfig.range"
                                        :options="sliderConfig.options"
                                        :disabled="dataModel.isLoading"> 
                                    </slider>
                                </div>
                                <div class="flex-column align-self-center p-2">Any</div>
                            </div>
                        </div>
                        <div class="row" v-if="!searchModel.location.enabled">
                            <div class="col-md-12">
                                <alert type="warning">
                                    <div class="alert-icon">
                                        <i class="far fa-frown"></i>
                                        </div>
                                        Location is disabled, cannot filter practitioners based on distance.
                                </alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <!-- Loading indicator -->
                    <div class="d-flex flex-row justify-content-center" v-if="dataModel.isLoading">
                        <loading-indicator></loading-indicator>
                        <div>&nbsp;Loading practitioners...</div>
                    </div>

                    <!-- Data -->
                    <fade-transition>
                        <div class="d-flex flex-row">
                            <div class="col-md-12 ml-auto mr-auto" v-if="!dataModel.isLoading && dataModel.practitioners.length === 0">
                                No practitioners found that match the search criteria <i class="far fa-frown"></i>
                            </div>

                            <div class="col-md-12" v-if="!dataModel.isLoading && dataModel.practitioners.length > 0">
                                <div class="display-wrapper d-flex flex-row justify-content-sm-center align-content-center" v-if="dataModel.count > 10">
                                    <div class="align-self-center">Display&nbsp;</div>
                                    <div class="align-self-center">
                                        <select class="form-control" @change="updateDisplayTotal">
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                    </div>
                                    <div class="align-self-center">&nbsp;of a total of {{ dataModel.count }} practitioners.</div>
                                </div>

                                <div class="profile-wrapper row justify-content-center" v-for="index in dataModel.displayTotal" :key="index">
                                    <!-- Profile Picture -->
                                    <div class="profile-picture col-md-3">
                                        <div class="picture-wrapper d-flex flex-row justify-content-center align-content-center">
                                            <div class="align-self-center">
                                                <img alt="profile" class="rounded-circle" :src="getProfilePicture(dataModel.practitioners[index - 1])" lazy="loaded">
                                            </div>
                                        </div>
                                        <div class="profile-name text-center">
                                            <h3 class="mb-0 mt-2">{{ dataModel.practitioners[index - 1].title }} {{ dataModel.practitioners[index - 1].firstName }} {{ dataModel.practitioners[index - 1].lastName }}</h3>
                                            <h5 class="mt-0">{{ dataModel.practitioners[index - 1].type }}</h5>
                                        </div>
                                        <div class="d-flex flex-row align-content-center justify-content-center mt-2">
                                            <star-rating 
                                                v-model="dataModel.practitioners[index - 1].rating" 
                                                read-only 
                                                :show-rating="starConfig.showRating" 
                                                :glow="starConfig.glow"
                                                :animate="starConfig.animate"
                                                :star-size="starConfig.size"
                                                :increment="starConfig.increment">
                                            </star-rating>
                                        </div>
                                    </div>

                                    <!-- Content -->
                                    <div class="profile-content col-md-5 d-flex">
                                        <!-- Details -->
                                        <div class="d-flex flex-row">
                                            <div class="profile-content-details align-self-center">
                                                <!-- Website -->
                                                <div class="row">
                                                    <div class="col-3"><b>Website</b></div>
                                                    <div class="col-9">
                                                        {{ dataModel.practitioners[index - 1].website == "" ? "No website provided" : dataModel.practitioners[index - 1].website }}
                                                    </div>
                                                </div>

                                                <!-- Address -->
                                                <div class="row">
                                                    <div class="col-3"><b>Address</b></div>
                                                    <div class="col-9">
                                                        {{ dataModel.practitioners[index - 1].addressLine1 }}
                                                        <br />
                                                        {{ dataModel.practitioners[index - 1].addressLine2 }}
                                                    </div>
                                                </div>

                                                <!-- City -->
                                                <div class="row">
                                                    <div class="col-3"><b>City</b></div>
                                                    <div class="col-9">
                                                        {{ dataModel.practitioners[index - 1].city == "" ? "No city provided" : dataModel.practitioners[index - 1].city }}
                                                    </div>
                                                </div>

                                                <!-- State -->
                                                <div class="row">
                                                    <div class="col-3"><b>State</b></div>
                                                    <div class="col-9">
                                                        {{ dataModel.practitioners[index - 1].state == "" ? "No state provided" : dataModel.practitioners[index - 1].state }}
                                                    </div>
                                                </div>

                                                <!-- Country -->
                                                <div class="row">
                                                    <div class="col-3"><b>Country</b></div>
                                                    <div class="col-9">
                                                        {{ dataModel.practitioners[index - 1].country == "" ? "No country provided" : dataModel.practitioners[index - 1].country }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Actions -->
                                    <div class="profile-actions col-md-2">
                                        <div class="d-flex flex-row justify-content-center align-content-center">
                                            <button class="btn btn-primary align-self-center col-sm-12" outline="" @click="gotToProfile(dataModel.practitioners[index - 1].profileId)">View Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fade-transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormGroupInput as FgInput } from "@/components";
    import { Slider } from "@/components";
    import { Alert } from "@/components";
    import { FadeTransition } from "vue2-transitions";
    
    import StarRating from "vue-star-rating";
    import FindWellnessService from "@/services/findWellnessService";

    export default {
        name: "findwellness",
        components: {
            FgInput,
            Slider,
            Alert,
            FadeTransition,
            StarRating
        },
        data() {
            return {
                searchModel: {
                    text: "",
                    type: 0,
                    location: {
                        enabled: true,
                        distance: "50km",
                        latitude: 0,
                        longitude: 0
                    },                    

                    toJsonModel() {
                        var self = this;
                        return {
                            typeId: self.type,
                            searchText: self.text,
                            enableLocationSearch: self.location.enabled,
                            distance: (self.location.distance === "Any Location" ? 10000 : Number(self.location.distance.replace('km', ''))) * 1000, //convert from KM to Meters
                            latitude: self.location.latitude,
                            longitude: self.location.longitude     
                        };
                    }
                },
                dataModel: {
                    isLoading: false,
                    count: 0,
                    displayTotal: "50km",
                    practitionerTypes: [{
                        id: 0,
                        description: "All"
                    }],
                    practitioners: []
                },
                starConfig: {
                    glow: 4,
                    showRating: false,
                    animate: true,
                    size: 30,
                    increment: 0.5
                },
                sliderConfig: {
                    range: {
                        min: 0,
                        max: 1000
                    },
                    options: {
                        tooltips: true,
                        format: {
                            to: function (value) {
                                if(value === 1000) {
                                    return "Any Location";
                                }

                                return Math.round(value) + 'km';
                            },
                            from: function (value) {
                                return Number(value.replace('km', ''));
                            }
                        }
                    }
                }
            };
        },
        methods: {
            getCurrentLocation() {
                var self = this;
                var wellnessService = new FindWellnessService();
                wellnessService.getUserLocation(self.handleLocationResponse);
            },
            bindDistanceSliderEvent() {
                var self = this;
                self.$refs['distance-slider'].$el.noUiSlider.on("end", function () {
                    if(!self.dataModel.isLoading) {
                        self.getPractitioners(self.searchModel.toJsonModel());
                    }
                });
            },
            bindPractitionerTypeEvent() {
                var self = this;
                if(!self.dataModel.isLoading) {
                    self.getPractitioners(self.searchModel.toJsonModel());
                }
            },
            getPractitionerTypes() {
                var self = this;
                var wellnessService = new FindWellnessService();
                
                wellnessService.getPractitionerTypes(self.handleGetPractitionerTypesResponse);
            },
            getPractitioners(model) {
                var self = this;
                var wellnessService = new FindWellnessService();
                    
                self.dataModel.isLoading = true;
                wellnessService.searchForPractitioners(model, self.handleSearchResponse);
            },
            searchData() {
                var self = this;
                if(self.searchModel.text.length >= 3 && !self.dataModel.isLoading) {
                    self.getPractitioners(self.searchModel.toJsonModel());
                }
            },
            updateDisplayTotal(event) {
                var self = this;
                if(event.target.value == "all") {
                    self.dataModel.displayTotal = self.dataModel.count;
                    return;
                }

                self.dataModel.displayTotal = parseInt(event.target.value);
            },
            gotToProfile(profileId) {
                var self = this;
                self.$router.push({name:"profile", query: {profileId: profileId}}); 
            },

            handleLocationResponse(positionResponse) {
                var self = this;

                self.searchModel.location.enabled = positionResponse.enabled;
                self.searchModel.location.latitude = positionResponse.latitude;
                self.searchModel.location.longitude = positionResponse.longitude;
                
                self.getPractitioners(self.searchModel.toJsonModel());
            },
            handleGetPractitionerTypesResponse(typesResponse) {
                var self = this;

                if(typesResponse.status === 200) {
                    self.dataModel.practitionerTypes = self.dataModel.practitionerTypes.concat(typesResponse.data.result);
                }
            },
            handleSearchResponse(searchResponse) {
                var self = this;

                self.dataModel.isLoading = false;
                if(searchResponse.status === 200) {
                    self.dataModel.count = searchResponse.data.result.length;
                    self.dataModel.displayTotal = searchResponse.data.result.length > 10 ? 10 : searchResponse.data.result.length;
                    self.dataModel.practitioners = searchResponse.data.result;
                } else {
                    self.dataModel.practitioners = [];
                }
            },
            getProfilePicture(profile) {
                const missingPicturePlaceholder = "img/shared/profile_picture_placeholder.png";
                if(profile.profilePictureUrl === undefined || profile.profilePictureUrl === null) {
                    return missingPicturePlaceholder;
                }

                return profile.profilePictureUrl;
            },
            handleBookNow() {
                window.location.href = process.env.VUE_APP_SIGNEDINENDPOINT;
            }
        },
        beforeMount(){
            var self = this;

            self.getCurrentLocation();
            self.getPractitionerTypes();
        },
        mounted() {            
            var self = this;
            self.bindDistanceSliderEvent();
        },
        metaInfo() {
            return {
                title: "DrRemo | Find Wellness",
                meta: [
                    {
                        name: "description",
                        content: "Find practitioners near you. Search through a variety of wellness providers to find the care you need."
                    },
                    {
                        name: "robots",
                        content: "index, follow"
                    }
                ],
                link: [
                    {
                        rel: "canonical",
                        href: "https://dr-remo.co.za/findwellness"
                    }
                ]
            }
        }
    }
</script>