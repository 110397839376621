import axios from 'axios'

export default class FindWellnessService {
    getUserLocation(handleResponse) {
        try {
            if(navigator.permissions) {
                navigator.permissions.query({ name: 'geolocation' })
                .then(response => {
                    if(response.state === "denied") {
                        handleResponse({
                            enabled: false,
                            latitude: 0,
                            longitude: 0
                        });
    
                        return;
                    }
    
                    navigator.geolocation.getCurrentPosition(pos => {
                        handleResponse({
                            enabled: true,
                            latitude: pos.coords.latitude,
                            longitude: pos.coords.longitude
                        });
                    });
                });
    
                return;
            } else if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    handleResponse({
                        enabled: true,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                }, () => {
                    handleResponse({
                        enabled: false,
                        latitude: 0,
                        longitude: 0
                    });
                });
    
                return;
            } 
    
            handleResponse({
                enabled: false,
                latitude: 0,
                longitude: 0
            });
    
            return;
        }
        catch {
            handleResponse({
                enabled: false,
                latitude: 0,
                longitude: 0
            });
        }
    }
    
    searchForPractitioners(seachModel, handleResponse) {
        var endPoint = `${process.env.VUE_APP_GATEWAYENDPOINT}/practitioner/practitioner/get/search`;
        axios
            .post(endPoint, seachModel)
            .then(response => handleResponse(response))
            .catch(error => {
                handleResponse(error);
            });
    }

    getPractitionerTypes(handleResponse) {
        var endPoint = `${process.env.VUE_APP_GATEWAYENDPOINT}/practitioner/practitioner/get/used/types`;
        axios
            .get(endPoint)
            .then(response => handleResponse(response))
            .catch(error => {
                handleResponse(error);
            });
    }
}